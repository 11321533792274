import React from 'react';
import PageWrapper from '../components/PageWrapper';

const FourOhFour = () => {
    return(
        <PageWrapper>
            <div className="section">
                <div className="container">
                    <h1>404 Page not found.</h1>
                    <p>Oops! The page you are looking for has been moved or relocated.</p>
                </div>
            </div>
        </PageWrapper>
    );
}

export default FourOhFour;